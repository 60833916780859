.page{
    display: flex;
}

.rightsidebar{
    width: 30%;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.title{
    font-size: 30px;
    text-align: center;
    margin-top: 3rem;
}

.subTitle{
    margin-top: 0.5rem;
    font-size: 16px;
    text-align: center;
}

.contentContainer{
    width: 75%;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.contentContainer h3{
    margin-top: 2rem;
    font-size: 20px;
}

.contentContainer p{
    font-size: 16px;
    margin-top: 0.5rem;
}

.contentContainer ol, ul{
    margin-left: 2rem;
}

.tableCon{
    display: flex;
    justify-content: center;
}

.tableCon table {
    width: 70%;
    border-collapse: collapse;
    margin: 20px auto;
    text-align: center;
  }

.tableCon th, td {
    border: 1px solid black;
    padding: 8px;
  }

  .ads{
    margin-top: 3.5rem;
    height: auto;
    width: 75%;
  }

@media screen and (max-width: 990px) {
    .rightsidebar{
        display: none;
    }
    .container{
        min-height: 88vh;
        width: 100%;
    }
    
    .title{
        font-size: 25px;
        width: 95%;
    }
    
    .subTitle{
        margin-top: 0.5rem;
        font-size: 14px;
    }
    
.contentContainer{
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.contentContainer h3{
    margin-top: 1.5rem;
    font-size: 18px;
}

.contentContainer p{
    font-size: 14px;
}

.contentContainer ol, ul{
    margin-left: 1.5rem;
}

.tableCon table {
    width: 95%;
  }

  .ads{
    margin-top: 2.5rem;
    width: 95%;
  }
}