.Container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-height: 90vh;
    /* margin-bottom: 3rem; */
}

.title{
    text-align: center;
    font-size: 35px;
    margin-top: 3rem;
}

.subTitle{
    margin-top: 0.5rem;
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
}

.subHeadingCon{
    margin: 2rem 8.5vw 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subHeadings{
    font-size: 20px;
}

.pageLink{
    background: rgba(0, 0, 255,0.5);
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageLink img{
    width: 15px;
    height: 15px;
}

.toolsContainer{
    margin: 1rem 0 3rem;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-left: 8vw;
    background-color: transparent;
    /* justify-content: center; */
}
.conColor1{
    background-color: rgb(200, 240, 255);
    margin-top: 2rem;
}
.conColor2{
    background-color: rgb(255, 200, 200);
    margin-top: 2rem;
}
.conColor4{
    background-color: rgb(200, 255, 217);
    margin-top: 2rem;
}
.conColor3{
    background-color: rgb(238, 255, 200);
    margin-top: 2rem;
}

.toolsContainer a{
    width: 15.5vw;
    height: 4rem;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 990px) {
    .title{
        font-size: 30px;
        margin-top: 1.5rem;
    }
    
    .subTitle{
        font-size: 16px;
        width: 95%;
    }

    .subHeadingCon{
        margin: 1rem 4vw 0.2rem;
    }
    
    .subHeadings{
        font-size: 18px;
    }
    
    .pageLink{
        font-size: 14px;
    }
    
    .toolsContainer{
        margin: 1rem 0 2rem;
        gap: 15px;
        justify-content: center;
    }
    
    .toolsContainer a{
        width: 45%;
        height: 4rem;
        text-align: center;
    }
}