.Container{
    width: 100%;
    display: flex;
}

.navbar{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10;
    width: 100%
}


.pages{
    width: 100%;
    margin-top: 4rem;
    background-color: #F8F9FA;
}

@media screen and (max-width: 990px) {
    .Container{
        flex-direction: column;
    }
    
    .leftSidebar{
        display: none;
    }
    
    
    .pages{
        width: 100%;
        margin-top: 4rem;
    }
    
    .rightsidebar{
        display: none;
    }
}