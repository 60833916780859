.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 2rem;
}

.title{
    text-align: center;
    margin: 0.5rem 0;

}

.box, .boxOpen{
    height: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.boxOpen{
    height: auto;
}

.upperCon{
    height: 30px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    align-items: center;
    cursor: pointer;
}

.upperCon div{
    font-size: 16px;
    font-weight: 400;
}

.titles{
    font-weight: 700 !important;
}

.logoCon{
    transition: transform 0.3s ease; 
}

.rotateIcon {
    transform: rotate(90deg);
  }

.logoCon img{
    width: 22px;
    height: 22px;
    margin-top: 4px;
}

.lowerCon div{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    gap: 3px;
}

.lowerCon div a {
    color: black;
    font-size: 14px;
    text-decoration: none;
  }
  
  .active {
    color: rgba(160, 32, 240,1) !important;
  }

  .subCategory{
    font-weight: 700;
  }

  @media screen and (max-width: 990px) {
    .container{
        gap: 5px;
        margin: 1rem 0;
        padding-right: 1.5rem;
        min-height: 90vh;
    }
  }