.page{
    display: flex;
}

.rightsidebar{
    width: 30%;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.title{
    font-size: 30px;
    text-align: center;
    margin-top: 3rem;
}

.subTitle{
    margin-top: 0.5rem;
    font-size: 16px;
    text-align: center;
}

.innerCon{
    width: 70%;
    margin-top: 3rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.inputCon{
    width: 70%;
}

.inputplaceholder{
    margin-top: 2rem;
    margin-bottom: 5px;
    font-size: 16px;
}

.inputfield{
    width: 90%;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: #efefef;
}
.errorMsg{
    color: red;
    font-size: 14px;
    margin-top: 5px;
}


.additionalInputCon{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin-right: 0.5%;
}

.radioInput{
    margin-left: 1.5rem;
}

.addplaceholder{
    margin-top: 0.5rem;
    margin-bottom: 5px;
    font-size: 16px;
}

.addInputfield{
    width: 90%;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: #efefef;
}

.largeCheckbox {
    transform: scale(1.5);
    margin-right: 8px; /* Adjust spacing */
}


.outputCon{
    width: 67%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 2.3rem;
}

.outputplaceholder{
    font-size: 16px;
    font-weight: 600;
    margin-left: -2%;
}

.outputfield{
    color: rgba(160, 32, 240,1);
    font-size: 20px;
    font-weight: 700;
}

.btnCon{
    margin-top: 1.5rem;
    display: flex;
    width: 67%;
    justify-content: space-between;
}

.btnCon button{
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    width: 120px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.btnCon button img{
    width: 12px;
    display: none;
}

.convertBtn{
    background-color: rgba(160, 32, 240,1);
    color: white;
    margin-left: -2%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.clearBtn{
    background-color: #424242;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.swapBtn{
    background-color: rgba(255,255,255);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.contentContainer{
    width: 75%;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.contentContainer h3{
    margin-top: 2rem;
    font-size: 20px;
}

.contentContainer p{
    font-size: 16px;
    margin-top: 0.5rem;
}

.contentContainer ol, ul{
    margin-left: 2rem;
}

.tableCon{
    display: flex;
    justify-content: center;
}

.tableCon table {
    width: 70%;
    border-collapse: collapse;
    margin: 20px auto;
    text-align: center;
  }

.tableCon th, td {
    border: 1px solid black;
    padding: 8px;
  }

  .ads{
    margin-top: 3.5rem;
    height: auto;
    width: 75%;
  }

  .progressBarWrapper {
    width: 70%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
    margin-bottom: 2rem;
    margin-top: -1rem;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    border-radius: 10px;
    transition: width 0.5s ease; /* This will animate the width change */
}
@media screen and (max-width: 990px) {
    .rightsidebar{
        display: none;
    }
    .container{
        min-height: 88vh;
        width: 100%;
    }
    
    .title{
        font-size: 25px;
        width: 95%;
    }
    
    .subTitle{
        margin-top: 0.5rem;
        font-size: 14px;
    }
    
    .innerCon{
        width: 95%;
        margin-top: 3rem;
    }
    
    .inputCon{
        width: 85%;
    }
    
    .inputplaceholder{
        margin-top: 2rem;
        margin-bottom: 4px;
        font-size: 14px;
    }
    
    .inputfield{
        width: 90%;
        font-size: 14px;
    }
    .errorMsg{
        font-size: 12px;
        margin-top: 4px;
    }

    .additionalInputCon{
        width: 80%;
        gap: 0.5rem;
    }
    
    .outputCon{
        width: 85%;
        margin: 2rem 0 2.3rem;
    }
    
    .outputplaceholder{
        font-size: 14px;
        margin-left: 0%;
    }
    
    .outputfield{
        font-size: 18px;
    }
    
    .btnCon{
        margin-top: 1.5rem;
        display: flex;
        width: 85%;
        justify-content: space-between;
    }
    
    .btnCon button{
        padding: 10px 10px;
        width: 100px;
        font-size: 14px;
    }
    .convertBtn{
        margin-left: 0%;
    }

    
.contentContainer{
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.contentContainer h3{
    margin-top: 1.5rem;
    font-size: 18px;
}

.contentContainer p{
    font-size: 14px;
}

.contentContainer ol, ul{
    margin-left: 1.5rem;
}

.tableCon table {
    width: 95%;
  }

  .ads{
    margin-top: 2.5rem;
    width: 95%;
  }

  .progressBarWrapper{
    width: 85%;
  }
}