.ads{
    width: 100%;
    height: auto;
}

.leftSidebar{
    width: 68%;
    background-color: white;
    margin: 3rem 0;
    padding: 1rem 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}