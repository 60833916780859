.link{
    text-decoration: none;
    color: black;
}

.container{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 2rem 1rem;
    margin-top: 2.5rem;
    margin-right: 27%;
    border-radius: 20px;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(101,78,163,0.7) 0%, rgba(234,175,200,0.5) 100%);
}

.title{
    font-size: 25px;
    text-align: center;
}

.para{
    margin-top: 1rem;
    text-align: center;
    font-size: 16px;
}

.btnCon{
    margin-top: 1.3rem;
    display: flex;
    justify-content: center;
}

.btn{
    padding: 5px 20px;
    border-radius: 25px;
    border: 2px solid black;
    background-color: black;
    color: white;
    font-size: 14px;
}