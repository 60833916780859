.InnerContainer{
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0 5%;
}

.InnerContainerMobile{
    display: none;
}

.logoCon img{
    margin-top: 5px;
    width: 170px;
}

.pagesCon{
    display: flex;
    gap: 20px;
}

.link, .activelink{
    color: black;
    text-decoration: none;
}

.activelink{
    color: rgba(160, 32, 240,1);
}

.link:hover{
    color: rgba(160, 32, 240,0.4);
}

@media screen and (max-width: 990px) {
    .InnerContainer{
        display: none;
    }

    .InnerContainerMobile{
        height: 4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #ffffff;
        padding: 0 2%;
    }

    .menuIconDiv{
        margin-right: 20px;
    }

    .menuIconDiv img{
        width: 40px;
    }
    
    .logoCon img{
        margin-top: 2px;
        width: 150px;
    }

    .LeftSidebarCon{
        position: absolute;
        top: 4rem;
        left: 0;
        background-color: white;
        box-shadow: 5px 0 12px rgba(0, 0, 0, 0.8);
    }
    
}