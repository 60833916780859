.link{
    text-decoration: none;
    color: black;
}
.container{
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 5%;
    /* background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,238,221,1) 100%); */
    background: linear-gradient(to right, #1cd8d2, #93edc7);
}

.container h6{
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.container p{
    font-size: 16px;
    font-weight: 500;
    color: black;
    line-height: 20px;
}


.container button{
    display: flex;
    justify-content: center;
    width: 10rem;
    border: 1px solid black;
    background-color: black;
    color: white;
    padding: 10px 0;
    border-radius: 30px;
    font-size: 14px;
}

@media screen and (max-width: 990px) {
    .container{
        flex-direction: column;
        padding: 1.5rem 5%;
    }
    
    .container h6{
        font-size: 18px;
        text-align: center;
    }
    
    .container p{
        margin-top: 0.5rem;
        font-size: 14px;
        text-align: center;
    }
    
    
    .container button{
        font-size: 12px;
        margin-top: 1rem;
    }
}