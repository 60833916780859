@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Suez+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

*{
  margin: 0;
  padding: 0;
}

h1, h2,h3,h4,h5{
  font-family: "Suez One", serif;
  font-weight: 400;
}
h6{
  font-family: "Josefin Sans", serif;
  line-height: 30px;
}
p, div, a,button,ul, li, select, option{
  /* font-family: "Figtree", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

p,li{
  line-height: 30px;
}