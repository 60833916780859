.Container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-height: 90vh;
    margin-bottom: 3rem;
}

.title{
    text-align: center;
    font-size: 35px;
    margin-top: 3rem;
}

.subTitle{
    margin-top: 0.5rem;
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
}
.subHeadings{
    font-size: 20px;
    margin-left: 8.5vw;
    margin-top: 2rem;
}
.toolsContainer{
    margin: 1rem 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-left: 8vw;
    /* justify-content: center; */
}

.toolsContainer a{
    width: 15.5vw;
    height: 4rem;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #393A39;
    text-align: center;
}

@media screen and (max-width: 990px) {
    .title{
        font-size: 30px;
        margin-top: 1.5rem;
    }
    
    .subTitle{
        font-size: 16px;
        width: 95%;
    }

    .subHeadings{
        font-size: 18px;
        margin-left: 5vw;
        margin-top: 1.5rem;
    }
    
    .toolsContainer{
        margin: 1rem 0;
        gap: 15px;
        justify-content: center;
    }
    
    .toolsContainer a{
        width: 45%;
        height: 4rem;
    }
}