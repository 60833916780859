.Container{
    background-color: #393A39;
    
    padding: 2rem 9%;
}

.innerCon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 2rem 0;
}

.firstDiv div img{
    width: 150px;
}

.firstDiv p{
    color: white;
}

.secondDiv, .thirdDiv, .fourthDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.secondDiv a, .thirdDiv a{
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}

.secondDiv a:hover, .thirdDiv a:hover{
    color: #DA392B;
}

.fourthDiv p{
    font-size: 16px;
    color: white;
    font-weight: 600;
}

.fourthDiv div{
    display: flex;
    gap: 10px;
}

.fourthDiv div img{
    width: 25px;
    border-radius: 7px;
    cursor: pointer;
}

.fourthDiv div img:hover{
    box-shadow: 2px 2px 0 #DA392B;
}

@media (max-width: 990px) {
    .Container{
        padding: 2rem 5%;
    }
    
    .innerCon{
        flex-wrap: wrap;
        padding: 2rem 8%;
    }

    .firstDiv{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .firstDiv div img{
        width: 120px;
    }
    
    .firstDiv p{
        font-size: 14px;
    }
    
    .secondDiv, .thirdDiv, .fourthDiv{
        margin-top: 1.5rem;
        gap: 8px;
    }
    
    .secondDiv a, .thirdDiv a{
        font-size: 14px;
    }
    
    .fourthDiv{
        width: 100%;
    }
    
    .fourthDiv p{
        font-size: 14px;
    }
    
    .fourthDiv div img{
        width: 25px;
    }
}