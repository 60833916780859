.popupCon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .noScroll {
    overflow: hidden; /* Disables scrolling */
    height: 100vh; /* Prevents extra movement */
  }
  
  .popupBox {
    background: white;
    padding: 3rem 2rem;
    width: 25rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    position: relative;
  }

  .closeIcon{
    position: absolute;
    width: 22px;
    right: 10px;
    cursor: pointer;
    top: 10px;
  }

  .closeIcon:hover{
    width: 26px;
    right: 8px;
    top: 8px;
  }
  
  .popupHeader {
    font-size: 25px;
    font-weight: 700;
  }
  
  .popupBody {
    font-size: 16px;
    color: #333;
    margin: 1.5rem 0 1.8rem;
  }
  
  .Btn {
    font-size: 14px;
    background: #000000;
    border: 2px solid #000000;
    color: white;
    padding: 8px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  
  .Btn:hover {
    background: #ffffff;
    color: #000000;
  }
  
  /* Smooth fade-in effect */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.2);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  