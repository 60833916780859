.innerCon{
    width: 70%;
    margin-top: 3rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.errorMsg{
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.inputplaceholder{
    margin-top: 1rem;
    margin-bottom: 10px;
    font-weight: 700;
}

.additionalInputCon{
    width: 69%;
    margin-top: 1.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 0.5%;
}

.additionalInputDiv{
    position: relative;
}

.addInputfield{
    width: 90%;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: #efefef;
    margin-top: 1rem;
}

.radioInputCon{
    margin-top: 1.5rem;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radioInputCon div{
    display: flex;
    align-items: center;
}

.radioInput, .radioInput2{
    width: 18px;
    height: 18px;
    margin-top: 2px;
    cursor: pointer;
}

.radioInputlabel{
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
}

.radioInput2{
    margin-left: 2rem;
}

.selectOption{
    width: 98%;
    padding: 10px 0 10px 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    background-color: #efefef;
    outline: none;
    border: none;
}

.selectOption option{
    cursor: pointer;
}

.outputCon{
    width: 67%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 2.3rem;
}

.outputplaceholder{
    font-size: 20px;
    font-weight: 600;
    margin-left: -2%;
}

.outputfield{
    /* color: rgba(160, 32, 240,1); */
    font-size: 20px;
    font-weight: 600;
}

.btnCon{
    margin-top: 1.5rem;
    display: flex;
    width: 67%;
    justify-content: space-between;
}

.btnCon button{
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 3.2rem;
    
}

.convertBtn{
    background-color: rgba(160, 32, 240,1);
    color: white;
    margin-left: -2%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.SearchBtn{
    background-color: white;
    color: black;
    margin-left: -1.5%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.clearBtn{
    background-color: #424242;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.swapBtn{
    background-color: rgba(255,255,255);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.suggestCon{
    position: absolute;
    background-color: white;
    width: 98%;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    max-height: 20rem;
    overflow-y: scroll;
}

.suggestedKeyword{
    padding: 5px 3%;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .innerCon{
        width: 95%;
        margin-top: 3rem;
    }
    
    .inputCon{
        width: 85%;
    }
    
    .inputplaceholder{
        margin-top: 1rem;
        margin-bottom: 4px;
        font-size: 14px;
    }
    
    .inputfield{
        width: 90%;
        font-size: 14px;
    }
    .errorMsg{
        font-size: 12px;
        margin-top: 4px;
    }

    .additionalInputCon{
        width: 85%;
        margin-top: 1rem;
        gap: 0rem;
    }
    
    .addInputfield{
        width: 90%;
        font-size: 14px;
    }

.radioInput, .radioInput2{
    width: 16px;
    height: 16px;
}

.radioInputlabel{
    font-size: 14px;
    margin-left: 7px;
}

.selectOption{
    width: 100%;
    font-size: 14px;
}

    .outputCon{
        width: 85%;
        margin: 2rem 0 2.3rem;
    }
    
    .outputplaceholder{
        font-size: 14px;
        margin-left: 0%;
    }
    
    .outputfield{
        font-size: 18px;
    }
    
    .btnCon{
        margin-top: 1.5rem;
        display: flex;
        width: 85%;
        justify-content: space-between;
    }
    
    .btnCon button{
        padding: 10px 10px;
        width: 100%;
        font-size: 14px;
    }
    .convertBtn{
        margin-left: 0%;
    }
}